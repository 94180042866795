import React, { useEffect, useState } from "react"
import { observer } from "mobx-react";
import { Box, Button, CircularProgress, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Switch, TextField, Typography } from "@mui/material";
import Layout from "../../../components/shared-layout";
import useStores from "../../../hooks/use-stores";
import VmTable from "../../../components/shared-table";
import VmButton from "../../../components/shared-button";
import { ApiDataListType, ApiDataResponseListLengthCheck, CRUDOperation } from "../../../constants/options";
import Breadcrumb from "../../../components/shared-breadcrumb";
import VmModal from "../../../components/shared-modal";
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { navigate } from "gatsby";
import { t } from "i18next";
import ContentLayout from "../../../components/shared-content-layout";
import { TD_FIRST, TD_LAST, TD_NORMAL } from "../../../constants/style";

const QuestionOptionPage = observer(({ location }) => {
  const { rootStore, questionStore } = useStores();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  // Determine is update or create
  const [targetOption, setTargetOption] = useState<any>();
  const [detail, setDetail] = useState<string>("");
  const [isCorrect, setIsCorrect] = useState<boolean>(false);
  useEffect(() => {
    if (!location.state || !location.state.subjectId || !location.state.categoryId ||
      !location.state.knowledgeId || !location.state.questionId) {
      navigate("/question/overview");
      return;
    }
    console.log("Option", location.state);
    questionStore.getQuestionListBySubjectId(location.state.subjectId)
      .then(() => {
        questionStore.onFilterTargetQuestion(
          location.state.categoryId, location.state.knowledgeId, location.state.questionId)
      });
  }, []);

  const onChangeIsCorrect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCorrect(event.target.checked);
  };

  const onActionQuestionOption = () => {
    let req: any = {
      id: 0,
      isCorrect,
      detail,
      questionId: location.state.questionId,
    }
    if (targetOption) {
      req.id = targetOption.id;
      questionStore.updateQuestionOption(req).then(() => {
        questionStore.getQuestionListBySubjectId(location.state.subjectId)
          .then(() => {
            rootStore.notify(t('QUESTION_OPTIONS') + t('UPDATED_B'), 'success');
            questionStore.onFilterTargetQuestion(
              location.state.categoryId, location.state.knowledgeId, location.state.questionId)
          }).catch(() => rootStore.notify(t('UPDATE_FAILED'), 'error'));
        handleCloseModal();
      });
    } else {
      questionStore.createQuestionOption(req).then(() => {
        questionStore.getQuestionListBySubjectId(location.state.subjectId)
          .then(() => {
            rootStore.notify(t('QUESTION_OPTIONS') + t('CREATED_B'), 'success');
            questionStore.onFilterTargetQuestion(
              location.state.categoryId, location.state.knowledgeId, location.state.questionId)
          }).catch(() => rootStore.notify(t('CREATE_FAILED'), 'error'));
        handleCloseModal();
      });
    }
  }

  const handleShowModal = (id?: number) => {
    if (id) {
      let target = questionStore.singleQuestionInfo.questionOptions.find(qo => qo.id == id);
      setTargetOption(target);
      setDetail(target.detail);
      setIsCorrect(target.isCorrect);
    }
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setShowDeleteModal(false);
    setTargetOption(null);
    setDetail("");
    setIsCorrect(false);
  }

  const handleDeleteModal = (id: number) => {
    let target = questionStore.singleQuestionInfo.questionOptions.find(qo => qo.id == id);
    setTargetOption(target);
    setShowDeleteModal(true);
  }

  return (
    <ContentLayout
      pageName={t('QUESTION_OPTIONS')}
      pageHeading={t('QUESTION_OPTIONS')}
      breadCrumb={[
        { label: t('QUESTION') + t('SETTING_B'), to: "" },
        { label: t('OVERVIEW'), to: "/question/overview" },
        { label: t('QUESTION_OPTIONS') + t('SETTING_B'), to: "/question/overview/question-option" }
      ]}
      buttonLabel={t('ADD_A') + t('OPTION')}
      onClickAction={() => handleShowModal()}
    >
      <VmTable
        loading={questionStore.loading}
        page={0}
        thead={["ID", t('OPTION_DETAIL'), t('IS_CORRECT'), t('ACTION')]}
        tbody={questionStore.singleQuestionInfo &&
          questionStore.singleQuestionInfo.questionOptions &&
          questionStore.singleQuestionInfo.questionOptions.map((option, index) => (
            <>
              <Box marginY={1} />
              <tr key={`option_${index}`}>
                <td className={TD_FIRST}>{index + 1}</td>
                <td className={TD_NORMAL} style={{ maxWidth: 300 }}>{option.detail}</td>
                <td className={`${TD_NORMAL} text-green-500`}>
                  {option.isCorrect ? <CheckIcon color={'inherit'} fontSize={'large'} /> : <ClearIcon color={'error'} fontSize={'large'} />}
                </td>
                <td className={TD_LAST}>
                  <Button
                    variant="outlined"
                    sx={{ marginRight: 2 }}
                    onClick={() => handleShowModal(option.id)}
                  >
                    {t('EDIT')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDeleteModal(option.id)}
                  >
                    {t('DELETE')}
                  </Button>
                </td>
              </tr>
              <Box marginY={1} />
            </>
          ))}
      />

      <VmModal
        openModal={showModal}
        onClose={handleCloseModal}
        title={targetOption ? t('UPDATE_A') + t('OPTION') : t('ADD_A') + t('OPTION')}
        buttonLabel={targetOption ? t('CONFIRM_A') + t('UPDATE') : t('CONFIRM_A') + t('CREATION')}
        buttonLabelWithoutConfirm={targetOption ? t('UPDATE_NOW') : t('CREATE_NOW')}
        onClickConfirmedButton={onActionQuestionOption}
        disabledCondition={detail === ""}
      >
        <TextField
          value={detail}
          label={t('DETAIL')}
          variant="outlined"
          sx={{ width: '100%', marginBottom: 1 }}
          rows={5}
          multiline
          onChange={(value) => setDetail(value.target.value)}
        />
        <div className="text-right">
          <FormControlLabel
            control={<Switch checked={isCorrect} onChange={onChangeIsCorrect} />}
            label={t('CORRECT_ANSWER')}
          />
        </div>
      </VmModal>

      <VmModal
        openModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title={t('DELETE_A') + t('CONFIRMATION')}
        buttonLabel={t('CONFIRM_DELETE')}
        buttonLabelWithoutConfirm={t('DELETE_NOW')}
        onClickConfirmedButton={() => questionStore.deleteQuestionOption(targetOption.id).then(() => {
          rootStore.notify(t('QUESTION_OPTIONS') + t('DELETED_B'), 'success');
          questionStore.onFilterTargetQuestion(
            location.state.categoryId, location.state.knowledgeId, location.state.questionId)
          handleCloseModal();
        }).catch(() => rootStore.notify(t('DELETE_FAILED'), 'error'))}
      >
        <Typography sx={{ marginBottom: 2 }}>{t('DELETE_CHECK')}?</Typography>
        <Typography color="red" variant="caption">
          {t('DELETE_INFO_EXAM')}
        </Typography>
      </VmModal>
    </ContentLayout>
  )
});

export default QuestionOptionPage;
